import { target } from '@github/catalyst';
import { controller } from './controller';
import { dispatchChange } from './util';

@controller('report-subquery-aggregation')
class ReportSubqueryAggregationElement extends HTMLElement {
  @target declare associationSelect: HTMLSelectElement;
  @target declare aggregateSelect: HTMLSelectElement;
  @target declare aggregationTargetFormGroup: HTMLElement;

  updateAggregation(event: Event) {
    const aggregateGroup =
      this.aggregateSelect.closest<HTMLElement>('.form-group')!;
    if (
      this.associationSelect.value === 'parent-activity' ||
      this.associationSelect.value === 'grandparent-activity'
    ) {
      aggregateGroup.hidden = true;
      this.aggregateSelect.value = 'max';
      if (event.target !== this.aggregateSelect) {
        dispatchChange(this.aggregateSelect);
      }
    } else if (aggregateGroup.hidden) {
      this.aggregateSelect.value = 'count';
      aggregateGroup.hidden = false;
    }

    const propertyInput = this.aggregationTargetFormGroup.querySelector(
      'input[name^="definition/"]'
    ) as HTMLInputElement;
    if (this.aggregateSelect.value === 'count') {
      // hide the property input
      this.aggregationTargetFormGroup.hidden = true;
      propertyInput.required = false;
    } else {
      // clear and show the property input
      this.aggregationTargetFormGroup.hidden = false;
      propertyInput.required = true;
      propertyInput.value = '';
      propertyInput.removeAttribute('value');
      propertyInput.removeAttribute('data-property');
      dispatchChange(propertyInput);
      propertyInput.previousElementSibling!.className =
        'color-fg-muted text-italic';
      propertyInput.previousElementSibling!.textContent = 'Select field...';

      // update the property input's remote input
      const remote =
        this.aggregationTargetFormGroup.querySelector('remote-input')!;
      const url = new URL(remote.getAttribute('src')!, location.origin);
      url.searchParams.set('association', this.associationSelect.value);
      url.searchParams.set('aggregate', this.aggregateSelect.value);
      remote.setAttribute('src', `${url.pathname}?${url.searchParams}`);
      const remoteFilterInput = remote.firstElementChild as HTMLInputElement;
      remoteFilterInput.value = '';
      dispatchChange(remoteFilterInput);
    }
  }
}

declare global {
  interface Window {
    ReportSubqueryAggregationElement: typeof ReportSubqueryAggregationElement;
  }
  interface HTMLElementTagNameMap {
    'report-subquery-aggregation': ReportSubqueryAggregationElement;
  }
}
